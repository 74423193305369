import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";
import { raw } from "@storybook/react";

type ResourceTypeName = string;
type ResourceTopicName = string;
type ResourceIndustryName = string;

export type ResourceType = {
  id: string;
  type: ResourceTypeName;
  display_in_filter_dropdown: boolean;
};

export type ResourceTopic = {
  id: string;
  doc_type: "resource_topic";
  topic: ResourceTopicName;
  display_in_filter_dropdown: boolean;
};

export type ResourceIndustry = {
  id: string;
  doc_type: "resource_industry";
  industry: ResourceIndustryName;
  display_in_filter_dropdown: boolean;
};

export type ResourceTag = ResourceTopic | ResourceIndustry;

export type ResourceFilter = {
  type?: ResourceType;
  industry?: ResourceIndustry;
  topic?: ResourceTopic;
};

export type PrismicRawResourceTopicDocument = {
  type: "resource_topic";
  document: {
    id: string;
    data: {
      display_in_filter_dropdown: boolean;
      topic: string;
    };
  };
};

export type PrismicRawResourceIndustryDocument = {
  type: "resource_industry";
  document: {
    id: string;
    data: {
      display_in_filter_dropdown: boolean;
      industry: string;
    };
  };
};

export type PrismicRawResourceTypeDocument = {
  document: {
    id: string;
    data: {
      display_in_filter_dropdown: boolean;
      type: string;
    };
  };
};

export type PrismicRawResourceData = {
  featured: boolean;
  featured_on_customer_page: boolean;
  unlisted: boolean;
  title: string;
  company_name: string;
  image: PrismicImage;
  description: PrismicRichText;
  tags: Array<{
    tag: PrismicRawResourceIndustryDocument | PrismicRawResourceTopicDocument;
  }>;
  link: {
    url: string;
    id: string;
    slug: string;
    type: "case_study" | null;
  };
  post_date: string;
  resource_type: PrismicRawResourceTypeDocument;
};

export type PrismicRawResourceEdge = {
  node: {
    data: PrismicRawResourceData;
  };
};

export type PrismicRawResourcePage = {
  data: {
    page_meta_description: string;
    title: string;
    meta_title: string;
    page_meta_thumbnail: PrismicImage;
    hero_image: PrismicImage;
    hero_text: PrismicRichText;
    hero_description: PrismicRichText;

    cta_text: string;
    cta_button_text: string;
    second_cta_button_text: string;
    cta_link: PrismicPageDefinition;
    second_cta_button_link: PrismicPageDefinition;
  };
};

export type PrismicRawEventsPage = {
  data: {
    page_meta_description: string;
    title: string;
    meta_title: string;
    page_meta_thumbnail: PrismicImage;
    hero_image: PrismicImage;
    hero_text: PrismicRichText;
    hero_description: PrismicRichText;

    cta_text: string;
    cta_button_text: string;
    second_cta_button_text: string;
    cta_link: PrismicPageDefinition;
    second_cta_button_link: PrismicPageDefinition;
  };
};

export type PrismicRawCaseStudyPage = {
  data: {
    page_meta_description: string;
    title: string;
    meta_title: string;
    page_meta_thumbnail: PrismicImage;
    hero_image: PrismicImage;
    hero_text: PrismicRichText;
    hero_description: PrismicRichText;

    cta_text: string;
    cta_button_text: string;
    cta_link: PrismicPageDefinition;
    second_cta_button_text: string;
    second_cta_button_link: PrismicPageDefinition;

    logo_section_text: string;
    logos: Array<{ logo: PrismicImage }>;
    quotes: Array<{
      author_name: string;
      author_position: string;
      quote: string;
      logo: PrismicImage;
    }>;
  };
};

export class Resource {
  title: string;
  image: PrismicImage;
  description: PrismicRichText;
  tags: Array<ResourceTag>;
  link: string;
  postDate: Date;
  resourceType: ResourceType;
  featured: boolean;
  featured_on_customer_page: boolean;
  unlisted: boolean;

  // Special cases:
  companyName: string;

  constructor(rawData: PrismicRawResourceData) {
    this.title = rawData.title;
    this.image = rawData.image;
    this.description = rawData.description;
    if (rawData.link.type === "case_study") {
      this.link = `/case-study/${rawData.link.slug}`;
    } else {
      this.link = rawData.link.url;
    }
    this.postDate = new Date(rawData.post_date);
    this.companyName = rawData.company_name;
    this.featured = rawData.featured;
    this.featured_on_customer_page = rawData.featured_on_customer_page;
    this.unlisted = rawData.unlisted;

    this.resourceType = {
      id: rawData.resource_type.document.id,
      display_in_filter_dropdown:
        rawData.resource_type.document.data.display_in_filter_dropdown,
      type: rawData.resource_type.document.data.type,
    };

    if (rawData.tags && rawData.tags.length) {
      this.tags = rawData.tags
        .map(Resource.convertRawTagDataToResourceTag)
        .filter((t) => t);
    }
  }

  static convertRawTagDataToResourceTag(rawTagData: {
    tag: PrismicRawResourceIndustryDocument | PrismicRawResourceTopicDocument;
  }): ResourceTag {
    if (!rawTagData.tag.document) {
      return null;
    }

    return {
      id: rawTagData.tag.document.id,
      doc_type: rawTagData.tag.type,
      ...rawTagData.tag.document.data,
    } as ResourceTag;
  }

  matchesFilters(filters: ResourceFilter) {
    if (filters.type) {
      if (filters.type.type !== this.resourceType.type) {
        return false;
      }
    }

    if (filters.industry) {
      if (
        this.tags.filter(
          (tag) =>
            tag.doc_type === "resource_industry" &&
            tag.industry === filters.industry.industry
        ).length === 0
      ) {
        return false;
      }
    }

    if (filters.topic) {
      if (
        this.tags.filter(
          (tag) =>
            tag.doc_type === "resource_topic" &&
            tag.topic === filters.topic.topic
        ).length === 0
      ) {
        return false;
      }
    }

    return true;
  }
}
