import "../product/projects.scss";
import "./aws.scss";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { PageTitle } from "../../components/title/title";
import Helmet from "react-helmet";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import { AmazeeIOButton } from "../../components/button/button";
import RichTextRender from "../../utils/rich-text";
import usePreviewData from "../../utils/usePreviewData";
import {
  TypePageBlock,
  TypeAWSPage,
  TypeAWSPageQueryResult,
} from "../../utils/queries/aws_page";
import classNames from "classnames";
import {
  CustomerStandardCard,
  StandardCard,
} from "../../templates/resources/cards";
import { Resource } from "../../utils/queries/resources";

const AWSPageBlock: React.FC<{
  block: TypePageBlock;
  index: number;
}> = ({ block, index }) => {
  const {
    image,
    image_placement,
    text,
    cta_text,
    cta_link,
    background_color,
  } = block;
  const imageEl = (
    <div
      className={classNames(
        "bg-cover background-item bg-center md:w-3/6 m-auto bg-no-repeat bg-contain",
        {}
      )}
      style={{
        height: index === 0 ? 380 : 320,
        backgroundImage: `url('${image.url}')`,
      }}
    />
  );
  const textEl = (
    <div className="item-block-text">
      {RichTextRender(text)}
      {cta_text && (
        <AmazeeIOButton classes="inverted inline-block mt-4">
          <a href={cta_link.url} target={cta_link.target}>
            {cta_text}
          </a>
        </AmazeeIOButton>
      )}
    </div>
  );

  return (
    <div
      className={classNames("py-10 row item-block justify-center", {
        "top-section": index == 0,
        "aws-region-launch": index == 1,
        "marketplace light-grey py-20": index % 2 === 0 && index !== 0,
        "py-20 last-section": index == 3,
      })}
      style={{
        backgroundColor: background_color,
        color: background_color ? "#fff" : "",
      }}
    >
      <div className={classNames("col-10 col-md-8 container", {})}>
        <div
          className={classNames("row", {
            "items-center": index > 0,
            "mt-10": index === 0,
          })}
        >
          <div className="block md:hidden col-12 col-md-6">
            <div className="md:px-4">{imageEl}</div>
          </div>
          <div className="block md:hidden col-12 col-md-6">{textEl}</div>

          {/* Desktop: Display in intended order */}
          <div className="hidden md:block col-12 col-md-6">
            {image_placement ? textEl : imageEl}
          </div>
          <div className="hidden md:block col-12 col-md-6 desktop-item-img">
            {image_placement ? imageEl : textEl}
          </div>
        </div>
      </div>
    </div>
  );
};

const AWSPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeAWSPageQueryResult;
}) => {
  const edge = data.allPrismicAwsPage.edges.slice(0, 1).pop();
  if (!edge) return null;
  const result = usePreviewData(
    edge.node.data,
    "prismicAwsPage",
    true
  ) as TypeAWSPage;

  const renderableResources = result.customer_stories.map(
    (rawResource) => new Resource(rawResource.case_study.document.data)
  );
  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="aws-page">
        <PageTitle title={title} amazeeOnly={true} />
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/solid.min.css"
          />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <section className="main pt-5 pb-10">
          <div className="overflow-x-hidden">
            <div className="justify-center">
              <div>
                {result.blocks.map((block, index) => (
                  <>
                    <AWSPageBlock index={index} key={index} block={block} />
                    {index == 1 ? (
                      <div
                        className="text-white customer-logos item-block -mt-12"
                        style={{
                          backgroundColor: "#252f3e",
                        }}
                      >
                        <div className="col-10 col-md-8 container">
                          <h3>{result.sub_title}</h3>
                          {result.customer_logos && (
                            <div className="flex flex-wrap pt-6 pb-20">
                              {result.customer_logos.map(
                                (customer_logo, index) => (
                                  <div
                                    key={index}
                                    className="bg-cover background-item bg-left w-1/3 md:w-28 ml-0 mr-8 md:mr-14 bg-no-repeat bg-contain"
                                    style={{
                                      height: 48,
                                      backgroundImage: `url('${customer_logo.customer_logo.url}')`,
                                    }}
                                  />
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section id="customers" className="light-grey py-10">
          <div className="container">
            <div className="row">
              {renderableResources.map((resource, index) =>
                resource.companyName ? (
                  <CustomerStandardCard resource={resource} key={index} />
                ) : (
                  <StandardCard resource={resource} key={index} />
                )
              )}
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticLagoonPage: React.FC<{ location: Location }> = ({ location }) => {
  const query = graphql`
    {
      allPrismicAwsPage {
        edges {
          node {
            data {
              page_meta_description
              title
              meta_title
              page_meta_thumbnail {
                url
              }
              sub_title
              blocks {
                text {
                  ...PrismicStructuredTextTypeFragment
                }
                image_placement
                background_color
                image {
                  url
                  alt
                }
                cta_text
                cta_link {
                  url
                  target
                }
              }
              customer_logos {
                customer_logo {
                  url
                  alt
                }
              }
              customer_stories {
                case_study {
                  document {
                    ... on PrismicResource {
                      id
                      data {
                        title
                        tags {
                          tag {
                            document {
                              ... on PrismicResourceIndustry {
                                id
                                data {
                                  industry
                                  display_in_filter_dropdown
                                }
                              }
                              ... on PrismicResourceTopic {
                                id
                                data {
                                  topic
                                  display_in_filter_dropdown
                                }
                              }
                            }
                          }
                        }
                        post_date
                        featured_on_customer_page
                        description {
                          html
                          raw
                          text
                        }
                        company_name
                        featured
                        link {
                          url
                          document {
                            ... on PrismicCaseStudy {
                              id
                              uid
                            }
                          }
                        }
                        image {
                          alt
                          url
                        }
                        resource_type {
                          document {
                            ... on PrismicResourceType {
                              id
                              data {
                                display_in_filter_dropdown
                                type
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    fragment PrismicStructuredTextTypeFragment on PrismicStructuredTextType {
      html
      raw
      text
    }

    fragment PrismicPageDefinitionsDataTypeFragment on PrismicPageDefinitionsDataType {
      url
      is_external
      friendly_name
    }
  `;

  return (
    <StaticQuery
      render={(result) => <AWSPage location={location} data={result} />}
      query={query}
    />
  );
};
export default StaticLagoonPage;
