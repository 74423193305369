import React from "react";
import { Resource } from "../../utils/queries/resources";
import RichTextRender from "../../utils/rich-text";
import { AmazeeIOButton } from "../../components/button/button";

const CardImage: React.FC<{ image: Resource["image"] }> = ({ image }) => (
  <img
    src={image.url}
    alt={image.alt}
    className="resource-image w-full h-full object-cover object-center"
    loading="lazy"
  />
);

const actionForResourceType = (type: string) => {
  switch (type) {
    case "eBook":
      return "Download the eBook";
    case "White Paper":
      return "Download the White Paper";
    case "Event":
      return "View the Event";
    case "Webinar":
      return "View the Webinar";
    case "Video":
      return "Watch the Video";
    case "Case Study":
      return "Read the Case Study";
    case "Blog":
      return "Read the Blog";
  }

  return "Learn More";
};
const FeatureCard: React.FC<{ resource: Resource }> = ({ resource }) => {
  let action = actionForResourceType(resource.resourceType.type);
  return (
    <a href={resource.link} className="resource-card-link feature block col-12">
      <div className="row">
        <div className="col-12">
          <div className="flex resource-card feature cursor-pointer">
            <div className="w-full h-full">
              <CardImage image={resource.image} />
            </div>
            <div className="w-full content h-full">
              <p className="content-type">{resource.resourceType.type}</p>
              <h2 className="content-title">{resource.title}</h2>
              <AmazeeIOButton classes="inverted">{action}</AmazeeIOButton>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const StandardCard: React.FC<{ resource: Resource }> = ({ resource }) => {
  return (
    <a
      href={resource.link}
      className="resource-card-link block col-12 col-md-4 "
    >
      <div className="resource-card cursor-pointer relative h-full">
        <div className="flex block-wrap flex-col h-full" style={{ height: 595 }}>
          <div className="w-full img-wrap flex-shrink-0" style={{ height: 258 }}>
            <CardImage image={resource.image} />
          </div>
          <div className="w-full content h-full">
            <p className="content-type">{resource.resourceType.type}</p>
            <div className="flex flex-col h-full">
              <h3 className="content-title">{resource.title}</h3>
              <div className="content-description line-clamp">
                {RichTextRender(resource.description)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const CustomerFeatureCard: React.FC<{ resource: Resource }> = ({
  resource,
}) => {
  return (
    <a href={resource.link} className="resource-card-link feature block col-12">
      <div className="row">
        <div className="col-12">
          <div className="resource-card feature cursor-pointer">
            <div className="w-full h-full">
              <CardImage image={resource.image} />
            </div>
            <div className="w-full content h-full">
              <p className="content-type">
                {resource.companyName} Case Study
              </p>
              <h2 className="content-title text-2xl font-semibold">{resource.title}</h2>
              <AmazeeIOButton classes="inverted">
                Read the Case Study
              </AmazeeIOButton>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const CustomerStandardCard: React.FC<{ resource: Resource }> = ({
  resource,
}) => {
  return (
    <a
      href={resource.link}
      className="resource-card-link block col-12 col-md-4 "
    >
      <div className="resource-card cursor-pointer relative">
        <div className="flex flex-col">
          <div className="w-full flex-shrink-0" /*style={{ height: 210 }} */>
            <CardImage image={resource.image} />
          </div>
          <div className="w-full content" style={{ minHeight: 335 }}>
            <p className="content-type">
              {resource.companyName} Case Study
            </p>
            <h3 className="content-title">{resource.title}</h3>
            <div className="content-description line-clamp">
              {RichTextRender(resource.description)}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};


const HomeCustomStandardCard: React.FC<{ resource: Resource }> = ({
  resource,
}) => {
  return (
    <a
      href={resource.link}
      className="resource-card-link-mod grid"
    >
      <div className="resource-card-mod cursor-pointer relative grid">
        <div className="">
          <div className="" /*style={{ height: 210 }} */>
            <CardImage image={resource.image} />
          </div>
          <div className="w-full content">
            <p className="content-type">
              {resource.companyName} Case Study
            </p>
            <h3 className="content-title">{resource.title}</h3>
            <div className="content-description line-clamp">
              {RichTextRender(resource.description)}
            </div>
          </div>
        </div>
        <div className="flex items-end">
          <a className="read-more-link" href={resource.link}>Read More</a>
        </div>
      </div>
    </a>
  );
};

const CustomStoriesCard: React.FC<{ resource: Resource, }> = ({ resource }) => (
  <a
    href={resource.link}
    className={`resource-card-link`}
  >
    <div className="resource-card cursor-pointer relative h-full">
      <div className="flex block-wrap flex-col h-full">
        <div className="w-full img-wrap flex-shrink-0" >
          <CardImage image={resource.image} />
        </div>
        <div className="w-full content h-full">
          <p className="content-type">{resource.resourceType.type}</p>
          <div className="flex flex-col h-full">
            <h3 className="content-title">{resource.title}</h3>
            <div className="content-description line-clamp">
              {RichTextRender(resource.description)}
            </div>
          </div>
          <a
            className="read-more-link"
            href={resource.link}
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  </a>
)

const CustomerStandardCardOpenBlank: React.FC<{ resource: Resource }> = ({
  resource,
}) => {
  return (
    <a
      target="_blank"
      href={resource.link}
      className="resource-card-link block col-12 col-md-4 "
    >
      <div className="resource-card cursor-pointer relative">
        <div className="flex flex-col" style={{ height: 600 }}>
          <div className="w-full flex-shrink-0" style={{ height: 210 }}>
            <CardImage image={resource.image} />
          </div>
          <div className="w-full content">
            <div className="content-type">
              {resource.companyName} Case Study
            </div>
            <div className="content-title">{resource.title}</div>
            <div className="content-description line-clamp">
              {RichTextRender(resource.description)}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export {
  CustomStoriesCard,
  FeatureCard,
  StandardCard,
  CustomerFeatureCard,
  CustomerStandardCard,
  CustomerStandardCardOpenBlank,
  HomeCustomStandardCard,
};
